import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import EditMembership from 'src/components/saas-memberships/edit-membership/edit-membership';
import SEO from 'src/components/seo';
import ROUTES from 'src/utils/routes';

const EditMembershipPage = () => {
  return (
    <Router basepath={ROUTES.SAAS_MEMBERSHIPS}>
      <EditMembershipRoute path="/:id/edit" />
    </Router>
  );
};

interface EditMembershipRouteProps extends RouteComponentProps {
  id?: string;
}

const EditMembershipRoute = ({ id }: EditMembershipRouteProps) => {
  return (
    <Layout>
      <SEO title="Edit Membership" />
      <PrivateRoute>
        <EditMembership id={id} />
      </PrivateRoute>
    </Layout>
  );
};

export default EditMembershipPage;
