import React, { useCallback, useState } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import ErrorPanel from 'src/components/error-panel/error-panel';
import MembershipForm from 'src/components/saas-memberships/membership-form/membership-form';
import { GET_MEMBERSHIP } from 'src/components/saas-memberships/saas-memberships-queries';
import Spinner from 'src/components/spinner/spinner';
import Redirect from 'src/components/util-components/redirect';
import { Membership, Membership_membership } from 'src/graphql-types/saas/Membership';
import ROUTES from 'src/utils/routes';

interface Props {
  id?: string;
}

const EditMembership = ({ id }: Props) => {
  const { t } = useTranslation();
  const [errorText, setErrorText] = useState<null | string>(null);
  const { data, loading, refetch } = useQuery<Membership>(GET_MEMBERSHIP, {
    client: meshGatewayClient,
    variables: { membershipId: id },
    skip: !id,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      setErrorText(
        t('queryError', {
          type: 'membershipsModule.queryErrors.getMembership',
          message: err.message,
        }),
      );
    },
  });
  const detailPageRoute = data?.membership ? `${ROUTES.SAAS_MEMBERSHIPS}/${data.membership.membershipId}` : '';

  const handleReset = useCallback(() => {
    refetch({ membershipId: id });
  }, [refetch, id]);

  const redirectToPrevPage = useCallback(() => {
    detailPageRoute && window.location.assign(detailPageRoute);
  }, [detailPageRoute]);

  if (!id || (data && !data.membership)) return <Redirect to={ROUTES.NOT_FOUND} />;

  if (errorText) return <ErrorPanel details={errorText} />;

  if (loading || !data) return <Spinner />;

  return (
    <MembershipForm
      existingMembership={data?.membership as Membership_membership}
      onReset={handleReset}
      onSubmitNoChange={redirectToPrevPage}
    />
  );
};

export default EditMembership;
